// colors

@bg: #ecf0f1;

@white: #ffffff;

@lightblue-1: #9ba3af;
@lightblue-2: #e3e8e9;
@lightblue-3: #d0dadc;
@lightblue-4: #d6e0e2;
@lightblue-5: #b8c2c4;

@darkblue-1: #34495e;
@darkblue-2: #273c52;
@darkblue-3: #445669;

@green: #25d56f;

@blue: #3498db;

@darkblue: #677786;

@red: #f24549;

@orange: #f5952d;


// geomanist fonts

.extralight() {
    font-family: 'geomanistextralight';
    font-weight: normal;
}

.thin() {
    font-family: 'geomanistthin';
    font-weight: normal;
}

.light() {
    font-family: 'geomanistlight';
    font-weight: normal;
}

.regular() {
    font-family: 'geomanist_regularregular';
    font-weight: normal;
}

.book() {
    font-family: 'geomanistbook';
    font-weight: normal;
}

.medium() {
    font-family: 'geomanistmedium';
    font-weight: normal;
}

.bold() {
    font-family: 'geomanistbold';
    font-weight: normal;
}

.black() {
    font-family: 'geomanistblack';
    font-weight: normal;
}

.ultra() {
    font-family: 'geomanistultra';
    font-weight: normal;
}

//elegant 
.elegant(@elegantcontent) {
    font-family: 'ElegantIcons';
    content: "\@{elegantcontent}";
}

//fontawesome 
.awesome(@awesomecontent) {
    font-family: 'FontAwesome';
    content: "\@{awesomecontent}";
}

// font-size
.fs(@fontsize: 14px) {
    font-size: @fontsize;
}

// line-height
.lh(@lineheight: 17px) {
    line-height: @lineheight;
}

// letterspacing
.ls(@letterspacing: 0px) {
    letter-spacing: @letterspacing;
}

// vertical align
.va(@verticalalign: top) {
    vertical-align: @verticalalign;
}

// vertical align
.ta(@textalign: left) {
    text-align: @textalign;
}

// width / height
.wh(@width, @height) {
    width: @width;
    height: @height;
}

// main css

@charset "utf-8";

// imports

@import "colors.less";
@import "elements.less";
@import "reset.less";



body {
    background: @bg;
    .regular();
    color: @darkblue-2;
}

// full site container

.container {
    display: table;
    width: 100%;
}

// main menu

.main-menu {
    display: none;
}

.menu-open .main-menu {
    display: table-cell;
    width: 300px;
    .va(top);
    overflow-x: hidden;
    background: @darkblue-1;
}

.logo-small {
    display: block;
    .wh(100%,60px);
    background: @green;
    .ta(left);
    color: @white;
    text-transform: uppercase;
    .medium();
    .fs(18px);
    .lh(56px);
    .ls(.5px);
    padding: 0 0 0 20px;
    .transition-duration(.1s);
}

.logo-small i {
    display: none;
}

.main-menu ul {
    padding: 20px 0 0 0;
    list-style: none;
}

.main-menu ul li {
    display: block;
}

.main-menu ul li.sep {
    height: 1px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid @darkblue-3;
}

.main-menu ul li a {
    display: block;
    .fs(14px);
    .lh(40px);
    .ls(.5px);
    color: @lightblue-1;
    .transition-duration(.15s);
    text-transform: uppercase;
    .book();
    padding: 0 20px;
}

.main-menu ul li a i {
    .fs(16px);
    width: 20px;
    .ta(center);
    margin-right: 10px;
}

.main-menu ul li a:hover {
    color: @white;
    .transition-duration(.2s);
}

.main-menu ul li a.active {
    background: @darkblue-2;
    color: @white;
}

.main-menu ul li a.active i {
    color: @green;
}


// main content

.main-content {
    display: table-cell;
    width: auto;
}

// header

.header {
    .wh(100%,60px);
    border-bottom: 1px solid @lightblue-2;
    background: @white;
    .ta(center);
}

.menu-btn {
    position: relative;
    .wh(45px,45px);
    float: left;
    .ta(center);
    .lh(45px);
    .fs(20px);
    margin: 7px 0 0 10px;
    cursor: pointer;
    color: @darkblue-2;
    z-index: 1;
    .transition-duration(.15s);
}

.menu-btn:hover {
    color: @green;
    .transition-duration(.2s);
}

.menu-btn:after {
    .awesome(f0c9);
}

.menu-open .menu-btn {
    color: @lightblue-1;
}

.menu-open .menu-btn:hover {
    color: @darkblue-1;
}

.menu-open .menu-btn:after {
    .awesome(f00d);
}

.logo-large {
    position: absolute;
    display: block;
    z-index: 0;
    width: 100%;
}

.logo-large a {
    color: @green;
    text-transform: uppercase;
    .medium();
    .fs(18px);
    .lh(58px);
    .ls(.5px);
    padding: 0 20px;
    .transition-duration(.1s);
}

.logo-large a:hover {
    .opacity(.8);
    .transition-duration(.1s);
}

.menu-open .logo-large { 
    display: none;
}

.logged-in-user {
    position: relative;
    display: inline-block;
    float: right;
    color: @lightblue-1;
    height: 60px;
    padding: 13px 20px 0 0;
    z-index: 1;
    .transition-duration(.15s);
}

.logged-in-user img {
    .wh(33px,33px);
    border: 3px solid @lightblue-2;
    .rounded(50%);
    display: inline-block;
    .va(middle);
    margin: 0 3px 0 0;
    .transition-duration(.15s);
}

.logged-in-user span {
    display: inline-block;
    .va(middle);
    .fs(13px);
    .lh(13px);
    .ls(.5px);
    .book();
    text-transform: uppercase; 
}

.logged-in-user:hover {
    color: @darkblue-1;
    .transition-duration(.2s);
}

.logged-in-user:hover img {
    border: 3px solid @green;
    .transition-duration(.2s);
}

// breadcrumb

.breadcrumb {
    .wh(100%,35px);
    background: @white;
    padding: 0 20px;
}

.breadcrumb ul {
    list-style: none;
}

.breadcrumb ul li {
    display: inline;
    color: @lightblue-1;
    .regular();
    .fs(11px);
    .lh(35px);
}

.breadcrumb ul li:after {
    .awesome(f105);
    padding: 0 1px 0 5px;
}

.breadcrumb ul li:last-child:after {
    display: none;
}

.breadcrumb ul li a {
    color: @lightblue-1;
    text-decoration: underline;
    .transition-duration(.15s);
}

.breadcrumb ul li a:hover {
    color: @darkblue-1;
    .transition-duration(.2s);
}


// footer

.footer {
    .wh(100%,auto);
    background: @darkblue-2;
    color: @lightblue-1;
    padding: 20px 20px 25px 20px;
    .ta(center);
    .fs(12px);
    .lh(12px);
    text-transform: uppercase;
    .medium();
    .ls(1px);
    position: relative;
}

.footer i {
    .fs(4px);
    display: inline-block;
    .va(middle);
    padding: 0 5px;
}

.footer a {
    .regular();
    color: @lightblue-1;
    text-transform: none;
    text-decoration: underline;
    .ls(.5px);
}

.footer a:hover {
    text-decoration: none;
    color: @white;
}

// main

// top-line 

.top-line {
    min-height: 80px;
    background: @white;
    padding: 0 20px 0 20px;
    border-bottom: 1px solid @lightblue-3;
    position: relative;
    margin: 0 0 30px 0;
}

.top-line .buttons {
    position: absolute;
    bottom: 15px;
    right: 20px;
}

.top-line h1 {
    .fs(32px);
    .lh(32px);
    .extralight();
    position: absolute;
    bottom: 10px;
}

.top-line .submenu {
    position: absolute;
    bottom: 0;
    left: 20px;
    list-style: none;
}

.top-line .submenu li {
    float: left;
    margin-right: 10px;
}

.top-line .submenu li:last-child {
    margin-right: 0px;
}

.top-line .submenu li a {
    display: inline-block;
    padding: 0 10px 10px 10px;
    .fs(14px);
    .lh(14px);
    .ls(.5px);
    text-transform: uppercase;
    .book();
    color: @lightblue-1;
    .transition-duration(.15s);
}

.top-line .submenu li a:hover {
    color: @darkblue-2;
    .transition-duration(.2s);
}

.top-line .submenu li a.active {
    color: @darkblue-2;
    border-bottom: 4px solid @green;
}

// sec-heading

.sec-heading {
    border-bottom: 1px solid @lightblue-3;
    padding: 10px 0 0 0;
    position: relative;
}

.sec-heading h1 {
    .fs(32px);
    .lh(32px);
    .extralight();
    padding: 0 0 10px 20px;
}

.sec-heading .button {
    position: absolute;
    right: 20px;
    bottom: 15px;
}

// sort-filter

.sort-filter {
    height: 0;
    overflow: hidden;
    transition: max-height .2s cubic-bezier(0.75, 0.25, 0.25, 0.75);
    -webkit-transition: max-height .2s cubic-bezier(0.75, 0.25, 0.25, 0.75);
    -moz-transition: max-height .2s cubic-bezier(0.75, 0.25, 0.25, 0.75);
    -o-transition: max-height .2s cubic-bezier(0.75, 0.25, 0.25, 0.75);
}

.filter-open .sort-filter {
    height: auto;
    max-height: 1200px;
    transition: max-height 1s ease-out;
    -webkit-transition: max-height 1s ease-out;
    -moz-transition: max-height 1s ease-out;
    -o-transition: max-height 1s ease-out;
    background: @lightblue-4;
}

.filter-box {
    border-bottom: 1px solid @bg;
    padding: 15px 20px 0px 20px;
}

.half-size {
    padding-top: 0;
    padding-bottom: 0;
}

.filter-box .left {
    width: 50%;
    border-right: 1px solid @bg;
    padding: 15px 20px 5px 0;
}

.filter-box .right {
    width: 50%;
    padding: 15px 0 5px 20px;
}

.filter-box:last-child {
    border-bottom: 1px solid @lightblue-3;
}

.filter-open .active-btn {
    .box-shadow(inset 0 3px 0 rgba(52, 73, 94, 0.15));
    .transition-duration(0s);
}
.filter-btn:after {
    .fs(12px);
    position: relative;
    top: -1px;
    .transition-duration(.2s);
}

.filter-open .filter-btn:after {
    .rotation(180deg);
    .transition-duration(.2s);
}

.filter-label {
    .medium();
    .fs(12px);
    .lh(12px);
    color: @lightblue-1;
    margin: 0 0 15px 0;
    .ls(.5px);
    text-transform: uppercase;
    .transition-duration(.15s);
}

.filter-label a {
    display: none;
}

.filter-label a:before {
    .awesome(333);
    .fs(3px);
    .va(middle);
}

.active-filter .filter-label {
    color: @darkblue-2;
    .transition-duration(.2s);
}

.active-filter .filter-label a {
    display: inline-block;
    text-transform: none;
    .book();
    text-decoration: underline;
    color: @darkblue-2;
}

.active-filter .filter-label a:hover {
    color: @red;
}

.active-filter .filter-label a:before {
    content: "";
    display: inline-block;
    .wh(3px,3px);
    .rounded();
    background: @lightblue-1;
    margin: 0 7px;
    .va(middle);
}

.checkbox,
.radio {
    display: inline-block;
    margin: 0 30px 10px 0;
}

.filter-box .half-sizer {
    width: 50%;
    padding-right: 20px;
}

.box .half-sizer {
    width: 50%;
    padding-right: 30px;
    padding-top: 5px;
}

.half-sizer2 {
    width: 50%;
    padding-right: 30px;
    padding-top: 0;
}

.half-sizer3 {
    width: 50%;
    padding-right: 30px;
    padding-bottom: 5px
}


.input-w-button .text {
    position: relative;
}

.text input[type="button"] {
    position: absolute;
    right: 0;
    top: 0;
    .border-radius(3px,3px,0,0);
    height: 32px;
    border: 1px solid #333;
    background: @darkblue;
    color: @white;
    cursor: pointer;
    .fs(14px);
    .lh(30px);
    .ls(.5px);
    text-transform: uppercase;
    .book();
    padding: 0 10px;
    border: none;
}

.text input[type="button"]:hover {
    .box-shadow(inset 0 -3px 0 rgba(52, 73, 94, 0.15));
    .transition-duration(.1s);
}

.text input[type="button"]:active {
    .box-shadow(inset 0 3px 0 rgba(52, 73, 94, 0.15));
    .transition-duration(0s);
}

// pagination

.pagination {
    padding: 30px 20px;
    background: @lightblue-4;
    bordet-top: 1px solid @lightblue-3;
}

.pagination .left .select {
    width: auto;
    padding-right: 30px;
}

.pagination .right {
    
}

.pagination ul {
    list-style: none;
    .fs(0);
}

.pagination ul li {
    display: inline-block;
    .fs(14px);
    margin: 0 3px;
}

.pagination ul li:last-child {
    margin-right: 0;
}

.pagination ul li a {
    .fs(14px);
    .lh(32px);
    .book();
    .ta(center);
    min-width: 32px;
    height: 32px;
    display: block;
    background: @darkblue;
    .rounded(16px);
    color: @white;
    text-transform: uppercase;
}

.pagination ul li a.prev, 
.pagination ul li a.next {
    padding: 0 12px;
}

.pagination ul li a.prev i {
    padding-right: 7px;
}

.pagination ul li a.next i {
    padding-left: 7px;
}

.pagination ul li a:hover {
    .box-shadow(inset 0 -3px 0 rgba(52, 73, 94, 0.15));
    .transition-duration(.1s);
}

.pagination ul li a:active {
    .box-shadow(inset 0 3px 0 rgba(52, 73, 94, 0.15));
    .transition-duration(0s);
}

.pagination ul li a.active {
    background: @green;
    .box-shadow(inset 0 3px 0 rgba(52, 73, 94, 0.15));
    .transition-duration(0s);
}


// item-list

.item-list {
    padding: 30px 20px 20px 20px;
}

.item-list .items {
    list-style: none;
}

.item-list .items > li {
    display: block;
    position: relative;
    background: @white;
    border: 1px solid @lightblue-2;
    .rounded(3px);
    margin-bottom: 20px;
    .transition-duration(.1s);
    padding: 15px;
}

.item-list .items li .number {
    position: absolute;
    top: -12px;
    left: 0;
    color: @darkblue-2;
    .opacity(.33);
    .fs(10px);
    .lh(10px);
    .medium();
    .transition-duration(.1s);
}

.item-list .items > li:hover {
    border: 1px solid @lightblue-3;
    box-shadow: 0px 0px 5px 0px rgba(52, 73, 94, 0.15);
    .transition-duration(.15s);
}

.item-list .items li:hover .number {
    .opacity(.66);
    .transition-duration(.15s);
}

.item-list .items > .item-open,
.item-list .items > .item-open:hover {
    border: 1px solid @lightblue-5;
    box-shadow: 0px 0px 10px 0px rgba(52, 73, 94, 0.15);
    padding-bottom: 30px;
}

.item-list .items .item-open .number,
.item-list .items .item-open:hover .number {
    .opacity(1);
}

.item-list .items li .buttons {
    position: absolute;
    top: 10px;
    right: 15px;
}

.item-list .items li .buttons a {
    float: right;
    .ta(center);
    .wh(28px,28px);
    color: @darkblue-1;
    .opacity(.33);
    margin-left: 5px;
    .rounded(3px);
    .transition-duration(.1s);
}

.item-list .items .item-open .buttons a {
    min-width: 130px;
    float: none;
    display: block;
    margin-bottom: 5px;
}

.item-list .items li .buttons a i {
    .fs(16px);
    .lh(26px);
}

.item-list .items li .buttons a span {
    display: none;
}

.item-list .items .item-open .buttons a span {
    display: inline-block;
    padding: 0 0 0 5px;
    .medium();
    .fs(14px);
    .lh(26px);
    .va(middle);
    .ls(.5px);
    text-transform: uppercase;
}

.item-list .items li:hover .buttons a {
    background: @bg;
    .transition-duration(.1s);
    .opacity(1);
}

.item-list .items li:hover .buttons a i {
}

.item-list .items li:hover .buttons .blue-btn-mini {
    color: @blue;
}

.item-list .items li:hover .buttons .orange-btn-mini {
    color: @orange;
}

.item-list .items li:hover .buttons .red-btn-mini {
    color: @red;
}

.item-list .items li:hover .buttons a:hover {
    .box-shadow(inset 0 -3px 0 rgba(52, 73, 94, 0.15));
    .transition-duration(.1s);
}

.item-list .items li:hover .buttons a:active {
    .box-shadow(inset 0 3px 0 rgba(52, 73, 94, 0.15));
    .transition-duration(0s);
}

.item-list .items li:hover .buttons .blue-btn-mini:hover {
    color: @white;
    background: @blue;
}

.item-list .items li:hover .buttons .orange-btn-mini:hover {
    color: @white;
    background: @orange;
}

.item-list .items li:hover .buttons .red-btn-mini:hover {
    color: @white;
    background: @red;
}

.item-list .items .item-open .buttons .blue-btn-mini,
.item-list .items .item-open:hover .buttons .blue-btn-mini {
    color: @white;
    background: @blue;
    .opacity(1);
}

.item-list .items .item-open .buttons .orange-btn-mini,
.item-list .items .item-open:hover .buttons .orange-btn-mini {
    color: @white;
    background: @orange;
    .opacity(1);
}

.item-list .items .item-open .buttons .red-btn-mini,
.item-list .items .item-open:hover .buttons .red-btn-mini {
    color: @white;
    background: @red;
    .opacity(1);
}


.item-list .items li .show {
    position: absolute;
    right: 15px;
    bottom: 10px;
    .ta(right);
    cursor: pointer;
    .fs(10px);
    .lh(10px);
    .ls(.5px);
}

.item-list .items li .show .more {
    .opacity(0);
}

.item-list .items li .show i {
    padding: 0 0 0 5px;
}

.item-list .items li:hover .show .more {
    .opacity(.66);
    .transition-duration(.1s);
}

.item-list .items li:hover .show .more:hover {
    .opacity(1);
    .transition-duration(.1s);
}

.item-list .items li .show .less {
    display: none;
}

.item-list .items .item-open .show .less {
    display: block;
    .opacity(.66);
    .transition-duration(.1s);
}

.item-list .items .item-open .show .less:hover {
    display: block;
    .opacity(1);
    .transition-duration(.1s);
}

.item-list .items .item-open .show .more {
    display: none;
}

.item-list .items li .title {
    .book();
    max-width: 80%;
    .fs(16px);
    .lh(21px);
    .ls(.5px);
    margin: 0 0 10px 0;
}

.item-list .items li .title i {
    .va(middle);
    .fs(7px);
    .lh(21px);
    padding: 0 5px;
}

.item-list .items li .title a {
    color: @darkblue-1;
}

.item-list .items li:hover .title a {
    color: @darkblue-1;
    text-decoration: underline;
}

.item-list .items li:hover .title a:hover {
    color: @blue;
    text-decoration: underline;
}

.item-list .items li .lead {
    display: none;
}

.item-list .items li img {
    display: none;
}

.item-list .items .item-open .lead {
    display: block;
    .regular();
    max-width: 70%;
    .fs(14px);
    .lh(17px);
    .ls(.5px);
    margin: 0 0 10px 0;
}

.item-list .items .item-open img {
    display: block;
    float: left;
    max-width: 160px;
    height: auto;
    margin: 0 15px 0 0;
    border: 1px solid @lightblue-2;
}

.item-list .items li .details {
    list-style: none;
    .fs(14px);
    .lh(19px);
}

.item-list .items li .details li {
    display: inline-block;
}

.item-list .items li .details li:before {
    content: "";
    .wh(4px,4px);
    display: inline-block;
    background: @lightblue-1;
    .rounded(50%);
    .va(middle);
    margin: 0 5px 0 6px;
}

.item-list .items li .details li:first-child:before {
    display: none;
}

.item-list .items .item-open .details li {
    display: block;
}

.item-list .items .item-open .details li:before {
    display: none;
}

.item-list .items li .subtitle {
    display: none;
}

.item-list .items .item-open .subtitle {
    display: inline-block;
    .book();
}

.item-list .items li .details li .param {
    color: @lightblue-1;
}

.item-list .items li .details li .param a {
    color: @lightblue-1;
    .book();
}

.item-list .items li:hover .details li .param a {
    text-decoration: underline;
}

.item-list .items li:hover .details li .param :hover {
    color: @darkblue-2;
}

.item-list .items li .details .show-when-open {
    display: none;
}

.item-list .items .item-open .details .show-when-open {
    display: block;
}

.item-list .items li .hide-when-open {
    display: inline-block;
}

.item-list .items .item-open .hide-when-open {
    display: none;
}


.item-list .items li .owner {
    display: none;
}

.item-list .items .item-open .owner {
    display: block;
    position: absolute;
    left: 15px;
    bottom: 10px;
    .ta(right);
    cursor: pointer;
    .fs(10px);
    .lh(10px);
    .ls(.5px);
}

.item-list .items .item-open .owner a {
    color: @darkblue-2;
}

.item-list .items .item-open .owner a:hover {
    text-decoration: underline;
}

// main

.main {
    margin: 20px;
    padding-bottom: 20px;
    min-height: 800px;
}

.tabbed {
    margin-top: 0;
}

.tab-menu {
    margin-top: 30px;
    margin-right: 20px;
    margin-left: 20px;
    position: relative;
    top: 1px;
}

.tab-menu ul {
    list-style: none;
}

.tab-menu ul:after {
    content: "";
    display: block;
    clear: both;
}

.tab-menu li {
    display: inline-block;
    float: left;
}

.tab-menu li a span {
    padding: 0 5px 0 0;
}

.tab-menu li a {
    height: 45px;
    padding: 0 20px;
    .fs(15px);
    .lh(44px);
    .ls(.5px);
    .medium();
    color: @lightblue-1;
    .transition-duration(.15s);
    display: block;
    .border-radius(3px,0,0,3px);
}

.tab-menu li a:hover {
    color: @darkblue-2;
    .transition-duration(.2s);
}

.tab-menu li a.active {
    background: @white;
    color: @darkblue-2;
    border-top: 1px solid @lightblue-2;
    border-left: 1px solid @lightblue-2;
    border-right: 1px solid @lightblue-2;
}

.content {
    background: @white;
    border: 1px solid @lightblue-2;
    .rounded(3px);
}

.main .box {
    padding: 30px 30px 15px 30px;
    border-bottom: 1px solid @lightblue-2;
}

.main .box:last-child {
    border-bottom: none;
}

.main .box .left {
    width: 50%;
    padding-right: 30px;
}

.main .box .right {
    width: 50%;
    padding-left: 30px;
}



.main .ta-right-buttons {
    .ta(right);
    padding-bottom: 40px;
    position: relative;
}

.main .prio-bottom {
    padding-bottom: 65px;
}

.main .ta-right-buttons .info {
    position: absolute;
    bottom: 15px;
    left: 30px;
}

.error-msg {
    display: none;
}
    
.error .error-msg {
    display: inline-block;
    text-transform: none;
    .book();
    color: @red;
}

.error .error-msg:before {
    content: "";
    display: inline-block;
    .wh(3px,3px);
    .rounded();
    background: @lightblue-1;
    margin: 0 7px;
    .va(middle);
}

.box .select {
    width: 100%;
}

.box .columns .checkbox,
.box .columns .radio {
    display: block;
    float: left;
    width: 33.33%;
    margin-right: 0;
}

.box .columns:after {
    content: "";
    display: block;
    clear: both;
}

.box .opening-hours {
    .va(bottom);
    font-size: 14px;
    line-height: 14px;
}

.opening-hours td {
    padding: 0 0 13px 0;
}

.opening-hours td.day {
    padding-top: 10px;
    padding-right: 20px;
}

.opening-hours .checkbox {
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
}

.opening-hours td.sep {
    .ta(center);
    width: 30px;
}

.opening-hours td.inp {
    width: 65px;
}

.closed-today .sep span,
.closed-today .open-txt {
    display: none;
}

.box .input-w-button .text {
    width: 100%;
}

// files table-layout: 

.files {
    width: 100%;
    margin: 0 0 25px 0;
}

.files tr {
    //.transition-duration(.15s);
    background: @white;
}

.files tr:hover {
    background: #f9fafa;
    //.transition-duration(.15s);
}

.files .thead:hover {
    background: @white;;
}

.files tr th {
    .medium();
    font-weight: normal;
    .fs(12px);
    .lh(12px);
    border-bottom: 1px solid @lightblue-2;
    .ta(left);
    padding: 10px;
    color: @lightblue-1;
    .ls(.5px);
    text-transform: uppercase;
}

.files tr td {
    border-bottom: 1px solid @lightblue-2;
    padding: 10px;
    .va(top);
    color: @lightblue-1;
    //.transition-duration(.15s);
}


.files tr td.pic {
    width: 120px;
}

.files tr .num {
    width: 20px;
    .ta(center);
}

.files tr .date {
    width: 150px;
    .ta(left);
}

.files tr td img {
    max-width: 120px;
    height: auto;
    border: 1px solid @lightblue-2;
    .opacity(.8);
    //.transition-duration(.15s);
}

.files tr td .cont-title {
    color: @darkblue-2;
}

.files tr td .cont-title:hover {
    text-decoration: underline;
}

.files tr:hover td img {
    .opacity(1);
    //.transition-duration(.15s);
}

.files tr .operations {
    width: 100px;
    white-space: nowrap;
    .ta(center);
}

.files tr .operations a,
.files tr .operations span {
    display: inline-block;
    .ta(center);
    color: @darkblue-2;
    .opacity(.33);
    //.transition-duration(.15s);
    margin: 0 7px;
}

.files tr:hover .operations a,
.files tr:hover .operations span {
    .opacity(.66);
    //.transition-duration(.15s);
}

.files tr:hover .operations a:hover,
.files tr:hover .operations span:hover {
    .opacity(1);
    //.transition-duration(.15s);
}

.files tr .operations .del:hover {
    color: @red;
}

.files tr.pinned .operations .pin {
    color: @red;
    .opacity(1);
}

.files tr .operations .view:hover {
    color: @orange;
}

.files tr .operations .move {
    cursor: move;
}

.files tbody tr.ui-sortable-helper {
    background: @white;;
    .opacity(.97);
    box-shadow: 0px 0px 10px 0px rgba(52, 73, 94, 0.15);
}

.sort-table-container {
    max-height: 600px;
    overflow-y: scroll;
    padding-bottom: 10px;
}

.box .separator {
    .wh(100%,1px);
    border-bottom: 1px solid @lightblue-2;
    margin: 0 0 20px;
    padding: 0 0 10px 0;
}

.add-new-line {
    display: block;
    padding: 10px 0;
}

.add-new-line a {
    display: inline-block;
    .book();
    .fs(14px);
    .lh(14px);
    color: @darkblue-2;
    text-decoration: underline;
}

.add-new-line a:hover {
    color: @blue;
    text-decoration: none;
}

.input-row-style {
    padding: 6px 0 13px 0;
}

.box .input-row .w65,
.box .w65{
    width: 65px;
}

.extra-btn {
    padding: 5px 0 10px 0;
}

.cron {
    margin: 0;
}

.cron .select {
    float: left;
    width: 15%;
    margin-left: 2.5%;
}

.cron input {
    float: left;
    width: 65%;
    margin: 0;
}

.on-the-main-page {
    float:left;
    white-space:nowrap
}

.on-the-main-page .select-title,
.on-the-main-page .w65 {
    display: inline-block;
    .va(baseline);
}

.on-the-main-page .select-title {
    padding-right: 5px;
    .book();
}

.login {

}

.login .main-menu,
.login .header,
.login .breadcrumb,
{
    display: none;
}

.login .container .main-content {
    background-image: url('../img/login-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.login-header {
    .wh(100%,60px);
    background: @green;
    .ta(center);
    margin: 0 0 180px;
}

.login-header a {
    display: inline-block;
    color: @white;
    text-transform: uppercase;
    .medium();
    .fs(18px);
    .lh(58px);
    .ls(.5px);
    padding: 0 20px;
}

.login-box {
    max-width: 380px;
    margin: 0 auto 180px auto;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(52, 73, 94,.35);
    .rounded(3px);
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 0px 0px 15px 0px rgba(52, 73, 94, 0.40);
}

.login-box .title {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(52, 73, 94,.20);
    padding: 20px 20px;
}

.login-box .title h1 {
    .fs(32px);
    .lh(28px);
    .extralight();
    .ls(.5px);
    color: @darkblue-2;
}

.login-box .login-content {
    padding: 20px;
}

.login-content .input-row {
    position: relative;
}

.login-content .input-row input {
    padding-left: 30px;
}

.login-content .input-row i {
    position: absolute;
    top: 25px;
    left: 0;
    .wh(32px,32px);
    .ta(center);
    .fs(14px);
    .lh(32px);
    color: @lightblue-1;
    z-index: 2;
}

.login-content .input-row input:focus + i {
    color: @green;
}

.login-content .error input + i {
    color: @red;
}

.login-box .checkbox {
    padding: 5px 0 10px 0;
}

.login-box .button {
    margin: 0 0 5px 0;
    height: 40px;
    .rounded(20px);
    padding: 0 20px;
    .fs(16px);
    .lh(40px);
    .ls(1px);
}

.login-error .error-msg {
    .fs(12px);
    margin: 0 0 20px 0;
}

.login-error .error-msg:before {
    display: none;
}

.files .input-row label .error-msg:before {
    display: none;
}

// stat

.stat {
    padding: 30px 20px 40px 20px;
}

.stat table {
    min-width: 600px;
    width: 100%;
}

.stat table tr {
    background: @white;
}

.stat table tr:first-child,
.stat table tr:first-child:hover {
    background: none;
}

.stat table tr:hover {
    background: #f9fafa;
}
.stat table tr th {
    font-weight: normal;
    .medium();
    .ta(left);
    .fs(12px);
    .lh(12px);
    border-bottom: 1px solid @lightblue-2;
    .ta(left);
    padding: 10px;
    color: @lightblue-1;
    .ls(.5px);
    text-transform: uppercase;
}

.stat table tr th a {
    color: @lightblue-1;
    display: block;
    white-space: nowrap;
}

.stat table tr th a:hover,
.stat table tr th a.asc,
.stat table tr th a.desc {
    color: @darkblue-2;
    
}

.stat table tr th a:after {
    display: inline-block;
    .awesome(f0dc);
    padding: 0 0 0 7px;
    .fs(12px);
    position: relative;
}

.stat table tr th a.asc:after {
    .awesome(f0de);
    top: 2px;
}

.stat table tr th a.desc:after {
    .awesome(f0dd);
    top: -2px;
}

.stat table tr td {
    border-bottom: 1px solid @lightblue-2;
    padding: 10px 0 10px 10px;
    .va(top);
    color: @darkblue-1;
}

.stat table tr td:last-child {
    padding-right: 10px;
}

.stat tr .num {
    width: 20px;
    .ta(center);
}




// media queries...

@import "1200w.less";
@import "960w.less";
@import "768w.less";
@import "480w.less";
// 768w

@media screen and (max-width: 768px) {
    
.logged-in-user span {
    display: none;
}


// top-line 

.top-line {
    min-height: auto;
    background: @white;
    padding: 10px 20px 0 20px;
    border-bottom: 1px solid @lightblue-3;
    position: relative;
    margin: 0 0 10px 0;
}

.top-line .buttons {
    position: static;
    margin: 0 0 15px 0;
}

.top-line .buttons .button {
    margin: 0 10px 0 0;
}

.top-line h1 {
    .fs(24px);
    .lh(24px);
    .light();
    position: static;
    margin: 0 0 10px 0;
}

.top-line .submenu {
    position: static;
    list-style: none;
    overflow-x: scroll;
    white-space: nowrap;
}

.top-line .submenu li {
    float: none;
    margin-right: 10px;
    display: inline-block;
}

// sec-heading

.sec-heading {
    border-bottom: 1px solid @lightblue-3;
    padding: 10px 0 0 0;
    position: relative;
}

.sec-heading h1 {
    .fs(24px);
    .lh(24px);
    .extralight();
    padding: 0 0 10px 20px;
}

.sec-heading .button {
    position: static;
    right: 20px;
    margin: 0 0 15px 20px;
}


// sort-filter

.filter-box {
    border-bottom: none;
    padding: 15px 20px 0px 20px;
}

.half-size {
    padding-top: 0;
    padding-bottom: 0;
}

.filter-box .left {
    float: none;
    width: 100%;
    border-right: none;
    padding: 15px 0 0px 0;
}

.filter-box .right {
    float: none;
    width: 100%;
    padding: 15px 0 0px 0;
}

.filter-box:last-child {
    border-bottom: 1px solid @lightblue-3;
}

.filter-box .half-sizer {
    width: 100%;
    padding-right: 0;
}

.box .half-sizer {
    width: 100%;
    padding-right: 0;
    padding-top: 0;
}

.half-sizer2 {
    width: 100%;
    padding-right: 0;
    padding-top: 0;
}

.half-sizer3 {
    width: 100%;
    padding-right: 0;
    padding-bottom: 0;
}

.select {
    width: 100%;
}

.text {
    width: 100%;
}


// pagination

.pagination {
    padding: 30px 20px;
    background: @lightblue-4;
    bordet-top: 1px solid @lightblue-3;
}

.pagination .left {
    float: none;
}

.pagination .left .select {
    width: auto;
    padding-right: 0px;
}

.pagination .right {
    float: none;
}

.pagination ul {
    list-style: none;
    .fs(0);
    .ta(center);
}

.pagination ul li {
    display: inline-block;
    .fs(12px);
    margin: 0 1px;
}

.pagination ul li:last-child {
    margin-right: 0;
}

.pagination ul li a {
    .fs(11px);
    .lh(26px);
    .book();
    .ta(center);
    min-width: 26px;
    height: 26px;
    display: block;
    background: @darkblue;
    .rounded(13px);
    color: @white;
    text-transform: uppercase;
}

.pagination ul li a.prev, 
.pagination ul li a.next {
    padding: 0 10px;
}

.pagination ul li a.prev i {
    padding-right: 5px;
}

.pagination ul li a.next i {
    padding-left: 5px;
}

// item-list

.item-list {
    display: block;
    padding: 30px 20px 20px 20px;
    overflow-x: scroll;
    margin-right: 20px;
}

.item-list .items {
    min-width: 600px;
}

.item-list .items li .show .more {
    .opacity(.66);
}

.item-list .items li .title {
    max-width: 450px;
}

.item-list .items li .details-container {
    max-width: 450px;
}


// main

.main {
    margin: 20px;
    padding-bottom: 20px;
    min-height: 800px;
    overflow-x: scroll;
}

.main .content {
    min-width: 600px;
}

.main .box .left {
    float: none;
    width: 100%;
    padding-right: 0;
}

.main .box .right {
    width: 100%;
    float: none;
    padding-left: 0;
}

.tabbed {
    margin-top: 0;
}

.tab-menu {
    overflow-x: scroll;
    margin-top: 30px;
    margin-right: 20px;
    margin-left: 20px;
    position: relative;
    top: 1px;
}

.tab-menu ul {
    display: block;
    list-style: none;
    white-space: nowrap;
}

.tab-menu ul:after {
    content: "";
    display: block;
    clear: both;
}

.tab-menu li {
    display: inline-block;
    float: none;
    white-space: nowrap;
}

.tab-menu li a span {
    padding: 0 5px 0 0;
}

.tab-menu li a {
    height: 45px;
    padding: 0 20px;
    .fs(15px);
    .lh(44px);
    .ls(.5px);
    .medium();
    color: @lightblue-1;
    .transition-duration(.15s);
    display: block;
    .border-radius(3px,0,0,3px);
}

.tab-menu li a:hover {
    color: @darkblue-2;
    .transition-duration(.2s);
}

.tab-menu li a.active {
    background: @white;
    color: @darkblue-2;
    border-top: 1px solid @lightblue-2;
    border-left: 1px solid @lightblue-2;
    border-right: 1px solid @lightblue-2;
}

.stat {
    margin-right: 20px;
    overflow-x: scroll;
}
    
}
// 960w

@media screen and (max-width: 960px) {
    
// full site container

.container {
    display: block;
    position: relative;
}

.main-menu {
    display: block;
    position: absolute;
    z-index: 999;
    left: -300px;
    width: 300px;
    background: @darkblue-1;
    .transition-duration(.2s);
}

.menu-open .main-menu {
    left: 0;
    display: block;
    overflow-x: hidden;
    .transition-duration(.3s);
    .box-shadow(0 0 10px rgba(52, 73, 94, 0.5));
    height: auto;
}

.logo-small i {
    display: inline-block;
    padding: 0 10px 0 0;
    .fs(20px);
    cursor: pointer;
}


// main content

.main-content {
    display: block;
    width: 100%;
}

    
}
// 480w

@media screen and (max-width: 480px) {
    
.login-box {
    width: auto;
    max-width: 100%;
    margin: 0 20px 180px 20px;
}
    
}
// reset & base

html {
    -webkit-text-size-adjust: 100%; 
    //-webkit-font-smoothing: antialiased;
    -webkit-font-smoothing: subpixel-antialiased;
}

* {
    margin: 0;
    padding: 0;
}


div, span, a, img, input, textarea, button, blockquote, li, h1, h2, h3, h4, h5, h6, header, footer {
    .box-sizing(border-box);
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}


img, p {
    display: block;
}

.clear {
    clear: both;
}

.clearfix:after {
    display: block;
    content: "";
    clear: both;
}

.left {
    float: left;
    text-align: left;
}

.right {
    float: right;
    text-align: left;
}

.center {
    margin: 0 auto;
}

button:focus, textarea:focus, input:focus, select:focus{
    outline: 0;
}

h1, h2, h3, h4, h5, h6 {
    display: block;
    font-weight: normal;
}

table {
    border-collapse: collapse;
}

strong, b {
    font-weight: normal;
}

::selection {
    background: #25d56f;
    color: @white;
}

::-moz-selection {
    background: #25d56f;
    color: @white;
}


// buttons

// normal button

.button {
    display: inline-block;
    height: 32px;
    .fs(14px);
    .lh(32px);
    .ls(.5px);
    white-space: nowrap;
    .rounded(16px);
    padding: 0 15px;
    .medium();
    text-transform: uppercase;
    .transition-duration(.1s);
    color: @white;
    margin-left: 10px;
    cursor: pointer;
    .user-select(none);
}

.button:before {
    padding-right: 7px;
}

.button:hover {
    .box-shadow(inset 0 -3px 0 rgba(52, 73, 94, 0.15));
    .transition-duration(.1s);
}

.button:active {
    .box-shadow(inset 0 3px 0 rgba(52, 73, 94, 0.15));
    .transition-duration(0s);
}

// button colors

.green-btn {
    background: @green;
}

.blue-btn {
    background: @blue;
}

.darkblue-btn {
    background: @darkblue;
}

.red-btn {
    background: @red;
}

.orange-btn {
    background: @orange;
}

.neutral-btn {
    color: @darkblue;
    .book();
    background: @lightblue-4;
}

// icons

.new-btn:before {
    .awesome(f067);
}

.save-btn:before {
    .awesome(f0c7);
}

.quit-btn:before {
    .awesome(f0e2);
}

.view-btn:before {
    .awesome(f06e);
}

.del-btn:before {
    .awesome(f1f8);
}

.export-btn:before {
    .awesome(f019);
}

.filter-btn:after {
    .awesome(f078);
    display: inline-block;
    margin: 0 0 0 7px;
    .ta(right);
}


// checkbox 

.checkbox {
    .user-select(none);
}

.checkbox input[type="checkbox"] {
    display: none;
}

.checkbox input[type="checkbox"] + label {
    display: inline-block;
    .fs(14px);
    .lh(14px);
    .book();
    .va(top);
    cursor: pointer;
    color: @darkblue-3;
}

.checkbox input[type="checkbox"] + label span {
    display: inline-block;
    padding: 0 0 0 5px;
    color: @lightblue-1;
    .medium();
    .fs(11px);
    .ls(.5px);
    .va(baseline);
}

.checkbox input[type="checkbox"] + label:before {
    content: "";
    .wh(18px,18px);
    .rounded(3px);
    background: @white;
    display: inline-block;
    position: relative;
    top: -4px;
    margin-right: 7px;
    .va(top);
    border: 1px solid @lightblue-3;
    .box-shadow(inset 0 -3px 0 rgba(52, 73, 94, 0.10));
    .transition-duration(.15s);
}

.checkbox input[type="checkbox"] + label:hover:before {
    border: 1px solid @lightblue-5;
    .transition-duration(.2s);
}

.checkbox input[type="checkbox"]:checked + label {
    color: @darkblue-2;
}

.checkbox input[type="checkbox"]:checked + label:before {
    .awesome(f00c);
    background: @green;
    .ta(center);
    border: 1px solid @green;
    color: @white;
    .fs(12px);
    .lh(18px);
    .box-shadow(inset 0 0 0 rgba(0, 0, 0, 0));
}

.checkbox input[type="checkbox"]:disabled + label {
    color: @lightblue-1;
    .regular();
    cursor: default;
    text-decoration: line-through;
}

.checkbox input[type="checkbox"]:disabled + label span {
    display: none;
}

.checkbox input[type="checkbox"]:disabled + label:before {
    border: 1px solid @lightblue-3;
    .box-shadow(inset 0 -3px 0 rgba(52, 73, 94, 0.10));
    .opacity(.5);
}


// radio 

.radio {
    .user-select(none);
}

.radio input[type="radio"] {
    display: none;
}

.radio input[type="radio"] + label {
    display: inline-block;
    .fs(14px);
    .lh(14px);
    .book();
    .va(top);
    cursor: pointer;
    color: @darkblue-3;
}

.radio input[type="radio"] + label span {
    display: inline-block;
    padding: 0 0 0 5px;
    color: @lightblue-1;
    .medium();
    .fs(11px);
    .ls(.5px);
    .va(baseline);
}

.radio input[type="radio"] + label:before {
    content: "";
    .wh(18px,18px);
    .rounded(50%);
    background: @white;
    display: inline-block;
    position: relative;
    top: -4px;
    margin-right: 7px;
    .va(top);
    border: 1px solid @lightblue-3;
    .box-shadow(inset 0 -3px 0 rgba(52, 73, 94, 0.10));
    .transition-duration(.15s);
}

.radio input[type="radio"] + label:hover:before {
    border: 1px solid @lightblue-5;
    .transition-duration(.2s);
}

.radio input[type="radio"]:checked + label {
    color: @darkblue-2;
}

.radio input[type="radio"]:checked + label:before {
    .awesome(f111);
    background: @green;
    .ta(center);
    border: 1px solid @green;
    color: @white;
    .fs(8px);
    .lh(18px);
    .box-shadow(inset 0 0 0 rgba(0, 0, 0, 0));
}

.radio input[type="radio"]:disabled + label {
    color: @lightblue-1;
    .regular();
    cursor: default;
    text-decoration: line-through;
}

.radio input[type="radio"]:disabled + label span {
    display: none;
}

.radio input[type="radio"]:disabled + label:before {
    border: 1px solid @lightblue-3;
    .box-shadow(inset 0 -3px 0 rgba(52, 73, 94, 0.10));
    .opacity(.5);
}



// styled select

.select {
    .wh(80%,32px);
    border: 1px solid @lightblue-3;
    .box-shadow(inset 0 -3px 0 rgba(52, 73, 94, 0.10));
    .rounded(3px);
    margin: 0 0 13px 0;
    position: relative;
    padding: 0 0 0 0;
    overflow: hidden;
    background: @white;
    .user-select(none);
    .transition-duration(.15s);
}

.select:hover {
    border: 1px solid @lightblue-5;
    .transition-duration(.2s);
}

.select select {
    .fs(14px);
    .lh(32px);
    .book();
    background: transparent !important;
    .wh(130%,30px);
    border: 0;
    border-radius: 0;
    -webkit-appearance: none;
    padding: 0 0 0 10px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    color: @darkblue-2;
}

.select:after {
    position: absolute;
    right: 10px;
    top: 0;
    .awesome(f0d7);
    z-index: 0;
    background: none;
    .fs(13px);
    .lh(32px);
}

//

.text {
    width: 80%;
}

.text input[type="text"] {
    .wh(100%,32px);
    border: 1px solid @lightblue-3;
    .box-shadow(inset 0 3px 0 rgba(52, 73, 94, 0.10));
    .rounded(3px);
    .fs(14px);
    .lh(32px);
    .book();
    background: @white;
    padding: 0 0 0 10px;
    margin: 0 0 13px 0;
    color: @darkblue-2;
    .transition-duration(.15s);
}

.text input[type="text"]:hover {
    border: 1px solid @lightblue-5;
    .transition-duration(.2s);
}

.text input[type="text"]:focus {
    border: 1px solid @green;
    .box-shadow(inset 0 3px 0 rgba(52, 73, 94, 0.10));
}

// input row

.input-row {
    margin: 0 0 20px 0;
}

.input-row label {
    .medium();
    .fs(12px);
    .lh(12px);
    color: @lightblue-1;
    margin: 0 0 12px 0;
    .ls(.5px);
    text-transform: uppercase;
    display: block;
}

.input-row input {
    .wh(100%,32px);
    border: 1px solid @lightblue-3;
    .box-shadow(inset 0 3px 0 rgba(52, 73, 94, 0.10));
    .rounded(3px);
    .fs(14px);
    .lh(32px);
    .book();
    padding: 0 10px;
    color: @darkblue-2;
    .transition-duration(.15s);
}

.input-row textarea {
    .wh(100%,140px);
    border: 1px solid @lightblue-3;
    .box-shadow(inset 0 3px 0 rgba(52, 73, 94, 0.10));
    .rounded(3px);
    .fs(14px);
    .lh(17px);
    .book();
    resize: none;
    padding: 5px 10px;
    color: @darkblue-2;
    .transition-duration(.15s);
}

.input-row input:hover,
.input-row textarea:hover {
    border: 1px solid @lightblue-5;
    .transition-duration(.2s);
}

.input-row input:focus,
.input-row textarea:focus {
    border: 1px solid @green;
    .box-shadow(inset 0 3px 0 rgba(52, 73, 94, 0.10));
}

.error input,
.error textarea {
    border-color: @red;
}

.important {
    position: relative;
}

.important:before {
    position: absolute;
    display: block;
    color: @red;
    .awesome(f069);
    .fs(6px);
    left: -10px;
    top: -3px;
}

.info {
    .fs(12px);
    .lh(12px);
    color: @lightblue-5;
    padding-top: 5px;
    .regular();
    .ta(left);
}

.info:before {
    .awesome(f05a);
    padding-right: 5px;
}

.open-txt {
    .wh(65px,32px);
    border: 1px solid @lightblue-3;
    .box-shadow(inset 0 3px 0 rgba(52, 73, 94, 0.10));
    .rounded(3px);
    .fs(14px);
    .lh(32px);
    .book();
    padding: 0 10px;
    color: @darkblue-2;
    .transition-duration(.15s);
    .ta(center);
}

.open-txt:hover {
    border: 1px solid @lightblue-5;
    .transition-duration(.2s);
}

.open-txt:focus {
    border: 1px solid @green;
    .box-shadow(inset 0 3px 0 rgba(52, 73, 94, 0.10));
}

.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    width: 100%;
}

.upload-btn {
}

.input-title input {
    height: 40px;
    .fs(16px);
    .lh(40px);
}

.input-lead textarea {
    height: 60px;
    .fs(16px);
    .lh(22px);
}

.input-body textarea.wysiwyg {
    font-family: 'Open Sans', sans-serif;
}

.seo-desc textarea {
    height: 80px;
    .fs(16px);
    .lh(22px);
}

// bannerkezelo

.banner-url {
    .wh(80%,32px);
    border: 1px solid @lightblue-3;
    .box-shadow(inset 0 3px 0 rgba(52, 73, 94, 0.10));
    .rounded(3px);
    .fs(14px);
    .lh(32px);
    .book();
    background: @white;
    padding: 0 0 0 10px;
    margin: 10px 0 5px 0;
    color: @darkblue-2;
    .transition-duration(.15s);
}

.banner-url:hover {
    border: 1px solid @lightblue-5;
    .transition-duration(.2s);
}

.banner-url:focus {
    border: 1px solid @green;
    .box-shadow(inset 0 3px 0 rgba(52, 73, 94, 0.10));
}